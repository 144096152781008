define("ember-uikit/components/uk-label", ["exports", "@ember/component", "ember-uikit/mixins/color", "ember-uikit/mixins/width", "ember-uikit/templates/components/uk-label"], function (_exports, _component, _color, _width, _ukLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LABEL_COLOR_OPTIONS = void 0;
  const LABEL_COLOR_OPTIONS = ["", _color.COLOR_OPTIONS.SUCCESS, _color.COLOR_OPTIONS.WARNING, _color.COLOR_OPTIONS.DANGER];
  _exports.LABEL_COLOR_OPTIONS = LABEL_COLOR_OPTIONS;

  var _default = _component.default.extend(_color.default, _width.default, {
    COLOR_OPTIONS: LABEL_COLOR_OPTIONS,
    layout: _ukLabel.default,
    tagName: "span",
    classNames: ["uk-label"],
    colorTemplate: "uk-label-$color$"
  });

  _exports.default = _default;
});