define("ember-uikit/components/uk-subnav", ["exports", "@ember/component", "ember-uikit/mixins/flex", "ember-uikit/templates/components/uk-subnav"], function (_exports, _component, _flex, _ukSubnav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_flex.default, {
    layout: _ukSubnav.default,
    tagName: "ul",
    classNames: ["uk-subnav"],
    classNameBindings: ["divider:uk-subnav-divider", "pill:uk-subnav-pill"],
    divider: false,
    pill: false
  });

  _exports.default = _default;
});