define("ember-uikit/components/uk-list/item", ["exports", "@ember/component", "ember-uikit/templates/components/uk-list/item"], function (_exports, _component, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _item.default,
    tagName: "li"
  });

  _exports.default = _default;
});