define("ember-uikit/components/uk-flex", ["exports", "@ember/component", "@ember/object/computed", "ember-uikit/mixins/flex", "ember-uikit/templates/components/uk-flex"], function (_exports, _component, _computed, _flex, _ukFlex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_flex.default, {
    layout: _ukFlex.default,
    flex: true,
    inline: (0, _computed.alias)("flexInline"),
    vertical: (0, _computed.alias)("flexVertical"),
    horizontal: (0, _computed.alias)("flexHorizontal"),
    direction: (0, _computed.alias)("flexDirection"),
    wrap: (0, _computed.alias)("flexWrap"),
    wrapAlignment: (0, _computed.alias)("flexWrapAlignment")
  });

  _exports.default = _default;
});