define("ember-uikit/components/uk-spinner", ["exports", "@ember/component", "ember-uikit/templates/components/uk-spinner"], function (_exports, _component, _ukSpinner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _ukSpinner.default,
    tagName: "div",
    attributeBindings: ["spinner:uk-spinner", "ratio"],
    spinner: "",
    ratio: 1
  });

  _exports.default = _default;
});