define("ember-uikit/components/uk-switcher", ["exports", "@ember/component", "@ember/object", "ember-uikit/templates/components/uk-switcher"], function (_exports, _component, _object, _ukSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _ukSwitcher.default,
    animation: "",
    contentId: (0, _object.computed)("elementId", function () {
      return `uk-switcher-content-${this.elementId}`;
    })
  });

  _exports.default = _default;
});