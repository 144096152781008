define("ember-uikit/components/uk-tab", ["exports", "@ember/component", "ember-uikit/mixins/flex", "ember-uikit/mixins/switcher", "ember-uikit/templates/components/uk-tab"], function (_exports, _component, _flex, _switcher, _ukTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_switcher.default, _flex.default, {
    layout: _ukTab.default,

    init() {
      this._super(...arguments);

      this.set("attributeBindings", this.attributeBindings.filter(i => i !== "ukSwitcher:uk-switcher"));
    },

    tagName: "ul",
    attributeBindings: ["ukSwitcher:uk-tab"],
    classNames: ["uk-tab"],
    classNameBindings: ["left:uk-tab-left", "right:uk-tab-right", "bottom:uk-tab-bottom"],
    left: false,
    right: false,
    bottom: false
  });

  _exports.default = _default;
});