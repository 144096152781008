define("ember-uikit/templates/components/uk-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "s7vVO3JS",
    "block": "[[[18,1,[[28,[37,1],null,[[\"header\",\"title\",\"body\",\"footer\"],[[50,\"uk-card/header\",0,null,null],[50,\"uk-card/title\",0,null,null],[50,\"uk-card/body\",0,null,null],[50,\"uk-card/footer\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-uikit/templates/components/uk-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});