define("ember-uikit/components/uk-subnav/link-item", ["exports", "@ember/routing/link-component", "ember-uikit/templates/components/uk-subnav/item"], function (_exports, _linkComponent, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _linkComponent.default.extend({
    layout: _item.default,
    tagName: "li",
    activeClass: "uk-active",
    classNameBindings: ["disabled:uk-disabled"],
    disabled: false
  });

  _exports.default = _default;
});