define("ember-uikit/-private/validated-computed-property", ["exports", "@ember/debug", "@ember/object"], function (_exports, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validatedComputedProperty = _exports.validatedArrayComputedProperty = _exports.validateValue = _exports.default = void 0;

  const validateValue = (value, name, options, mediaOptions) => {
    if (value && !options.filter(v => {
      const re = new RegExp(`^${v}(${mediaOptions.join("|")})?$`);
      return re.test(value);
    }).length) {
      (true && (0, _debug.warn)(`\`${name}\` must be one of ${options.map(v => `'${v}'`).join(", ")}. Passed was '${value}', which will therefore be ignored.`, {
        id: "ember-uikit.invalid-property"
      }));
      return null;
    }

    return value;
  };

  _exports.validateValue = validateValue;

  const validatedComputedProperty = function (key, name, optionsKey) {
    let mediaOptionsKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "MEDIA_OPTIONS";
    let sanitize = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : val => val || "";
    return (0, _object.computed)(key, `${optionsKey}.[]`, `${mediaOptionsKey}.[]`, {
      get() {
        return this.get(key);
      },

      set(_, value) {
        const validated = sanitize(validateValue(value, name, (0, _object.get)(this, optionsKey) ?? [], (0, _object.get)(this, mediaOptionsKey) ?? []));
        this.set(key, validated);
        return validated;
      }

    });
  };

  _exports.validatedComputedProperty = validatedComputedProperty;

  const validatedArrayComputedProperty = function (key, name, optionsKey) {
    let mediaOptionsKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "MEDIA_OPTIONS";
    let separator = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : " ";
    let sanitize = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : val => val || "";
    return (0, _object.computed)(key, `${optionsKey}.[]`, `${mediaOptionsKey}.[]`, {
      get() {
        return this.get(key);
      },

      set(_, value) {
        const validated = value && value.split(separator).map(v => sanitize(validateValue(v, name, (0, _object.get)(this, optionsKey) ?? [], (0, _object.get)(this, mediaOptionsKey) ?? []))).join(separator).trim();
        this.set(key, validated);
        return validated;
      }

    });
  };

  _exports.validatedArrayComputedProperty = validatedArrayComputedProperty;
  var _default = validatedComputedProperty;
  _exports.default = _default;
});