define("ember-uikit/mixins/flex", ["exports", "@ember/object", "@ember/object/mixin", "ember-uikit/-private/media", "ember-uikit/-private/validated-computed-property"], function (_exports, _object, _mixin, _media, _validatedComputedProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FLEX_WRAP_OPTIONS = _exports.FLEX_WRAP_ALIGNMENT_OPTIONS = _exports.FLEX_VERTICAL_OPTIONS = _exports.FLEX_HORIZONTAL_OPTIONS = _exports.FLEX_DIRECTION_OPTIONS = void 0;
  const FLEX_HORIZONTAL_OPTIONS = {
    LEFT: "left",
    CENTER: "center",
    RIGHT: "right",
    BETWEEN: "between",
    AROUND: "around"
  };
  _exports.FLEX_HORIZONTAL_OPTIONS = FLEX_HORIZONTAL_OPTIONS;
  const FLEX_VERTICAL_OPTIONS = {
    STRETCH: "stretch",
    TOP: "top",
    MIDDLE: "middle",
    BOTTOM: "bottom"
  };
  _exports.FLEX_VERTICAL_OPTIONS = FLEX_VERTICAL_OPTIONS;
  const FLEX_DIRECTION_OPTIONS = {
    ROW: "row",
    ROW_REVERSE: "row-reverse",
    COLUMN: "column",
    COLUMN_REVERSE: "column-reverse"
  };
  _exports.FLEX_DIRECTION_OPTIONS = FLEX_DIRECTION_OPTIONS;
  const FLEX_WRAP_OPTIONS = {
    WRAP: "wrap",
    WRAP_REVERSE: "wrap-reverse",
    NOWRAP: "nowrap"
  };
  _exports.FLEX_WRAP_OPTIONS = FLEX_WRAP_OPTIONS;
  const FLEX_WRAP_ALIGNMENT_OPTIONS = {
    STRETCH: "stretch",
    BETWEEN: "between",
    AROUND: "around",
    TOP: "top",
    MIDDLE: "middle",
    BOTTOM: "bottom"
  };
  _exports.FLEX_WRAP_ALIGNMENT_OPTIONS = FLEX_WRAP_ALIGNMENT_OPTIONS;

  var _default = _mixin.default.create({
    MEDIA_OPTIONS: Object.values(_media.default),
    FLEX_HORIZONTAL_OPTIONS: Object.values(FLEX_HORIZONTAL_OPTIONS),
    FLEX_VERTICAL_OPTIONS: Object.values(FLEX_VERTICAL_OPTIONS),
    FLEX_DIRECTION_OPTIONS: Object.values(FLEX_DIRECTION_OPTIONS),
    FLEX_WRAP_OPTIONS: Object.values(FLEX_WRAP_OPTIONS),
    FLEX_WRAP_ALIGNMENT_OPTIONS: Object.values(FLEX_WRAP_ALIGNMENT_OPTIONS),
    classNameBindings: ["flex:uk-flex", "flexInline:uk-flex-inline", "flexVerticalClass", "flexHorizontalClass", "flexDirectionClass", "flexWrapClass", "flexWrapAlignmentClass"],
    flex: false,
    flexInline: false,
    flexHorizontal: (0, _validatedComputedProperty.default)("_flexHorizontal", "flexHorizontal", "FLEX_HORIZONTAL_OPTIONS"),
    flexVertical: (0, _validatedComputedProperty.default)("_flexVertical", "flexVertical", "FLEX_VERTICAL_OPTIONS"),
    flexDirection: (0, _validatedComputedProperty.default)("_flexDirection", "flexDirection", "FLEX_DIRECTION_OPTIONS"),
    flexWrap: (0, _validatedComputedProperty.default)("_flexWrap", "flexWrap", "FLEX_WRAP_OPTIONS"),
    flexWrapAlignment: (0, _validatedComputedProperty.default)("_flexWrapAlignment", "flexWrapAlignment", "FLEX_WRAP_ALIGNMENT_OPTIONS"),
    flexHorizontalClass: (0, _object.computed)("flexHorizontal", function () {
      return this.flexHorizontal && `uk-flex-${this.flexHorizontal}`;
    }),
    flexVerticalClass: (0, _object.computed)("flexVertical", function () {
      return this.flexVertical && `uk-flex-${this.flexVertical}`;
    }),
    flexDirectionClass: (0, _object.computed)("flexDirection", function () {
      return this.flexDirection && `uk-flex-${this.flexDirection}`;
    }),
    flexWrapClass: (0, _object.computed)("flexWrap", function () {
      return this.flexWrap && `uk-flex-${this.flexWrap}`;
    }),
    flexWrapAlignmentClass: (0, _object.computed)("flexWrapAlignment", function () {
      return this.flexWrapAlignment && `uk-flex-wrap-${this.flexWrapAlignment}`;
    })
  });

  _exports.default = _default;
});