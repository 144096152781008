define("ember-uikit/components/uk-button", ["exports", "@ember/component", "ember-uikit/mixins/color", "ember-uikit/mixins/evented", "ember-uikit/mixins/size", "ember-uikit/mixins/width", "ember-uikit/templates/components/uk-button"], function (_exports, _component, _color, _evented, _size, _width, _ukButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BUTTON_COLOR_OPTIONS = void 0;
  const BUTTON_COLOR_OPTIONS = [_color.COLOR_OPTIONS.DEFAULT, _color.COLOR_OPTIONS.PRIMARY, _color.COLOR_OPTIONS.SECONDARY, _color.COLOR_OPTIONS.LINK, _color.COLOR_OPTIONS.TEXT];
  _exports.BUTTON_COLOR_OPTIONS = BUTTON_COLOR_OPTIONS;

  var _default = _component.default.extend(_color.default, _width.default, _size.default, _evented.default, {
    COLOR_OPTIONS: BUTTON_COLOR_OPTIONS,
    _color: _color.COLOR_OPTIONS.DEFAULT,
    layout: _ukButton.default,
    tagName: "button",
    colorTemplate: "uk-button-$color$",
    sizeTemplate: "uk-button-$size$",
    classNames: ["uk-button"],
    classNameBindings: ["active:uk-active"],
    attributeBindings: ["disabled", "type", "title"],
    label: "",
    type: "button",
    disabled: false,
    active: false,
    loading: false,
    title: ""
  });

  _exports.default = _default;
});