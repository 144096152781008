define("ember-uikit/components/uk-toggle-switch", ["exports", "@ember/object", "ember-toggle/components/x-toggle/component", "ember-uikit/-private/validated-computed-property"], function (_exports, _object, _component, _validatedComputedProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SIZE_OPTIONS = _exports.COLOR_OPTIONS = void 0;
  const SIZE_OPTIONS = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large"
  };
  _exports.SIZE_OPTIONS = SIZE_OPTIONS;
  const COLOR_OPTIONS = {
    DEFAULT: "default",
    ONOFF: "onoff"
  };
  _exports.COLOR_OPTIONS = COLOR_OPTIONS;

  var _default = _component.default.extend({
    init() {
      this._super(...arguments);

      this.setProperties({
        _size: SIZE_OPTIONS.MEDIUM,
        _color: COLOR_OPTIONS.DEFAULT
      });
    },

    SIZE_OPTIONS: Object.values(SIZE_OPTIONS),
    COLOR_OPTIONS: Object.values(COLOR_OPTIONS),
    classNames: ["uk-toggle-switch"],
    classNameBindings: ["colorClass"],
    value: false,
    name: null,
    showLabels: false,
    onLabel: null,
    offLabel: null,
    theme: "light",
    size: (0, _validatedComputedProperty.default)("_size", "size", "SIZE_OPTIONS"),
    color: (0, _validatedComputedProperty.default)("_color", "color", "COLOR_OPTIONS"),
    colorClass: (0, _object.computed)("color", function () {
      return `uk-toggle-switch-${this.color}`;
    }),
    onToggle: (0, _object.computed)(function () {
      return value => {
        const action = this["on-toggle"];

        if (typeof action === "function") {
          // one way binding
          action(value);
          return;
        } // two way binding
        // eslint-disable-next-line ember/no-side-effects


        this.set("value", value);
      };
    })
  });

  _exports.default = _default;
});