define("ember-uikit/components/uk-card/body", ["exports", "@ember/component", "ember-uikit/templates/components/uk-card/body"], function (_exports, _component, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _body.default,
    classNames: ["uk-card-body"]
  });

  _exports.default = _default;
});