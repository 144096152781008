define("ember-uikit/mixins/size", ["exports", "@ember/object", "@ember/object/mixin", "ember-uikit/-private/validated-computed-property"], function (_exports, _object, _mixin, _validatedComputedProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SIZE_OPTIONS = void 0;
  const SIZE_OPTIONS = {
    DEFAULT: "",
    SMALL: "small",
    LARGE: "large"
  };
  _exports.SIZE_OPTIONS = SIZE_OPTIONS;

  var _default = _mixin.default.create({
    SIZE_OPTIONS: Object.values(SIZE_OPTIONS),
    classNameBindings: ["sizeClass"],
    sizeTemplate: "uk-size-$size$",
    size: (0, _validatedComputedProperty.default)("_size", "size", "SIZE_OPTIONS"),
    sizeClass: (0, _object.computed)("_size", "size", "sizeTemplate", function () {
      return this.size && this.sizeTemplate.replace(/\$size\$/, this.size);
    })
  });

  _exports.default = _default;
});