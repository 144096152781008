define("ember-uikit/templates/components/uk-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wskZ8d2s",
    "block": "[[[41,[30,1],[[[1,[34,1]]],[]],[[[41,[48,[30,3]],[[[18,3,null]],[]],[[[1,[30,2]]],[]]]],[]]]],[\"@loading\",\"@label\",\"&default\"],false,[\"if\",\"uk-spinner\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-uikit/templates/components/uk-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});