define("ember-toggle/components/x-toggle-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0qR8wt+/",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[11,\"label\"],[16,\"for\",[30,0,[\"for\"]]],[16,0,[29,[\"toggle-text toggle-prefix \",[30,0,[\"labelType\"]]]]],[17,1],[4,[38,1],[\"click\",[28,[37,2],[[30,0],\"clickLabel\"],null]],null],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[18,2,[[30,0,[\"label\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"action\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-toggle/components/x-toggle-label/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});