define("ember-uikit/templates/components/uk-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EC3hEfK/",
    "block": "[[[40,[[[1,\"  \"],[10,0],[15,1,[30,0,[\"modalId\"]]],[15,0,[30,1]],[15,\"data-test-animating\",[30,0,[\"isAnimating\"]]],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[\"uk-modal-dialog \",[30,2]]]],[24,\"role\",\"dialog\"],[24,\"aria-modal\",\"true\"],[16,\"aria-labelledby\",[30,0,[\"modalHeaderId\"]]],[24,\"tabindex\",\"-1\"],[4,[38,2],null,[[\"isActive\",\"shouldSelfFocus\",\"focusTrapOptions\"],[[30,0,[\"focusTrapActive\"]],true,[28,[37,3],null,[[\"escapeDeactivates\",\"clickOutsideDeactivates\",\"returnFocusOnDeactivate\",\"preventScroll\"],[true,true,true,true]]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"btnClose\"]],[[[1,\"        \"],[10,\"button\"],[14,0,\"uk-modal-close-default\"],[14,\"uk-close\",\"\"],[14,4,\"button\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[18,3,[[28,[37,3],null,[[\"header\",\"body\",\"footer\"],[[50,\"uk-modal/header\",0,null,[[\"id\"],[[30,0,[\"modalHeaderId\"]]]]],[50,\"uk-modal/body\",0,null,null],[50,\"uk-modal/footer\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,1],[[30,0,[\"containerElement\"]]],null],null]],[\"@modalClass\",\"@dialogClass\",\"&default\"],false,[\"in-element\",\"-in-el-null\",\"focus-trap\",\"hash\",\"if\",\"yield\",\"component\"]]",
    "moduleName": "ember-uikit/templates/components/uk-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});