define("ember-uikit/mixins/color", ["exports", "@ember/object", "@ember/object/mixin", "ember-uikit/-private/validated-computed-property"], function (_exports, _object, _mixin, _validatedComputedProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COLOR_OPTIONS = void 0;
  const COLOR_OPTIONS = {
    NONE: "",
    DEFAULT: "default",
    MUTED: "muted",
    PRIMARY: "primary",
    SECONDARY: "secondary",
    SUCCESS: "success",
    WARNING: "warning",
    DANGER: "danger",
    LINK: "link",
    TEXT: "text"
  };
  _exports.COLOR_OPTIONS = COLOR_OPTIONS;

  var _default = _mixin.default.create({
    COLOR_OPTIONS: Object.values(COLOR_OPTIONS),
    classNameBindings: ["colorClass"],
    colorTemplate: "uk-$color$-background",
    color: (0, _validatedComputedProperty.default)("_color", "color", "COLOR_OPTIONS"),
    colorClass: (0, _object.computed)("color", "colorTemplate", function () {
      return this.color && this.colorTemplate.replace(/\$color\$/, this.color);
    })
  });

  _exports.default = _default;
});