define("ember-table/components/ember-td/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W6qenzXu",
    "block": "[[[41,[30,0,[\"isFirstColumn\"]],[[[1,\"  \"],[10,0],[14,0,\"et-cell-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"canSelect\"]],[[[1,\"      \"],[10,1],[15,0,[29,[\"et-toggle-select \",[52,[51,[30,0,[\"shouldShowCheckbox\"]]],\"et-speech-only\"]]]],[14,\"data-test-select-row-container\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"data-test-select-row\",\"checked\",\"onClick\",\"ariaLabel\"],[true,[30,0,[\"rowMeta\",\"isGroupSelected\"]],[28,[37,3],[[30,0],\"onSelectionToggled\"],null],\"Select row\"]]]],[1,\"\\n        \"],[10,1],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,4],[[[1,\"      \"],[10,1],[15,0,[29,[\"et-toggle-collapse et-depth-indent \",[30,0,[\"depthClass\"]]]]],[12],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"data-test-collapse-row\",\"checked\",\"onChange\",\"ariaLabel\"],[true,[30,0,[\"rowMeta\",\"isCollapsed\"]],[28,[37,3],[[30,0],\"onCollapseToggled\"],null],\"Collapse row\"]]]],[1,\"\\n        \"],[10,1],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[15,0,[29,[\"et-depth-indent et-depth-placeholder \",[30,0,[\"depthClass\"]]]]],[12],[13],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[10,0],[14,0,\"et-cell-content\"],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"        \"],[18,1,[[30,0,[\"cellValue\"]],[30,0,[\"columnValue\"]],[30,0,[\"rowValue\"]],[30,0,[\"cellMeta\"]],[30,0,[\"columnMeta\"]],[30,0,[\"rowMeta\"]],[30,0,[\"rowsCount\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"cellValue\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[30,0,[\"cellValue\"]],[30,0,[\"columnValue\"]],[30,0,[\"rowValue\"]],[30,0,[\"cellMeta\"]],[30,0,[\"columnMeta\"]],[30,0,[\"rowMeta\"]],[30,0,[\"rowsCount\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"cellValue\"]]],[1,\"\\n\"]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"unless\",\"-ember-table-private/simple-checkbox\",\"action\",\"canCollapse\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-table/components/ember-td/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});