define("ember-uikit/services/notification", ["exports", "@ember/service", "ember-get-config", "rsvp", "uikit"], function (_exports, _service, _emberGetConfig, _rsvp, _uikit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const USER_CONFIG = _emberGetConfig.default["ember-uikit"] && _emberGetConfig.default["ember-uikit"].notification || {};

  const notification = status => function (message) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return this._notification(message, Object.assign(options, {
      status
    }));
  };

  var _default = _service.default.extend({
    _notification(message) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      const n = _uikit.default.notification(Object.assign(USER_CONFIG, options, {
        message
      }));

      return n && n.$el ? new _rsvp.Promise(resolve => _uikit.default.util.on(n.$el, "close", resolve)) : (0, _rsvp.resolve)();
    },

    default: notification("default"),
    primary: notification("primary"),
    success: notification("success"),
    warning: notification("warning"),
    danger: notification("danger")
  });

  _exports.default = _default;
});