define("ember-uikit/templates/components/uk-card/title", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gZzlwP1D",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-uikit/templates/components/uk-card/title.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});