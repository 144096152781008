define("ember-uikit/templates/components/uk-label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yLh7INF2",
    "block": "[[[41,[48,[30,2]],[[[18,2,null]],[]],[[[1,[30,1]]],[]]]],[\"@label\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-uikit/templates/components/uk-label.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});