define("ember-uikit/mixins/width", ["exports", "@ember/object", "@ember/object/mixin", "ember-uikit/-private/media", "ember-uikit/-private/validated-computed-property"], function (_exports, _object, _mixin, _media, _validatedComputedProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gcd = _exports.default = _exports.WIDTH_OPTIONS = _exports.SPECIAL_OPTIONS = _exports.MAX_GRID = _exports.GRID_OPTIONS = _exports.GRID_NUMBERS = _exports.FIXED_OPTIONS = void 0;

  const gcd = (a, b) => a % b === 0 ? b : gcd(b, a % b);

  _exports.gcd = gcd;
  const MAX_GRID = 6;
  _exports.MAX_GRID = MAX_GRID;
  const GRID_NUMBERS = [...new Array(MAX_GRID).keys()].map(n => n + 1);
  _exports.GRID_NUMBERS = GRID_NUMBERS;
  const GRID_OPTIONS = Array.from(new Set(GRID_NUMBERS.reduce((all, of) => {
    return [...all, ...GRID_NUMBERS.filter(n => of >= n).map(n => {
      return `${n / gcd(n, of)}-${of / gcd(n, of)}`;
    })];
  }, []))).reduce((obj, val) => Object.assign(obj, {
    [val.replace(/-/, "_OF_")]: val
  }), {});
  _exports.GRID_OPTIONS = GRID_OPTIONS;
  const FIXED_OPTIONS = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
    XLARGE: "xlarge",
    XXLARGE: "xxlarge"
  };
  _exports.FIXED_OPTIONS = FIXED_OPTIONS;
  const SPECIAL_OPTIONS = {
    AUTO: "auto",
    EXPAND: "expand"
  };
  _exports.SPECIAL_OPTIONS = SPECIAL_OPTIONS;
  const WIDTH_OPTIONS = Object.assign({}, GRID_OPTIONS, FIXED_OPTIONS, SPECIAL_OPTIONS);
  _exports.WIDTH_OPTIONS = WIDTH_OPTIONS;

  var _default = _mixin.default.create({
    WIDTH_OPTIONS: Object.values(WIDTH_OPTIONS),
    MEDIA_OPTIONS: Object.values(_media.default),
    classNameBindings: ["widthClass"],
    _widthTemplate: "uk-width-$width$",
    width: (0, _validatedComputedProperty.validatedArrayComputedProperty)("_width", "width", "WIDTH_OPTIONS", "MEDIA_OPTIONS", " "),
    widthClass: (0, _object.computed)("_widthTemplate", "width", function () {
      return this.width && this.width.split(" ").map(w => this._widthTemplate.replace(/\$width\$/, w)).join(" ");
    })
  });

  _exports.default = _default;
});