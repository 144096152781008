define("ember-uikit/components/uk-card/header", ["exports", "@ember/component", "ember-uikit/templates/components/uk-card/header"], function (_exports, _component, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _header.default,
    classNames: ["uk-card-header"]
  });

  _exports.default = _default;
});