define("@html-next/vertical-collection/-debug/utils/validate-rect", ["exports", "@html-next/vertical-collection/-debug/utils/validate-style"], function (_exports, _validateStyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasDimension = hasDimension;
  _exports.hasDimensionAbove = hasDimensionAbove;
  _exports.hasDimensionEqual = hasDimensionEqual;

  function hasDimension(rect, prop) {
    return (0, _validateStyle.isNonZero)(rect[prop]);
  }

  function hasDimensionAbove(rect, prop, amount) {
    return hasDimension(rect, prop) && rect[prop] >= amount;
  }

  function hasDimensionEqual(rect, prop, amount) {
    return hasDimension(rect, prop) && rect[prop] === amount;
  }
});