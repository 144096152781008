define("ember-uikit/mixins/switcher", ["exports", "@ember/object", "@ember/object/mixin", "@ember/utils"], function (_exports, _object, _mixin, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    attributeBindings: ["ukSwitcher:uk-switcher", "connect", "active", "animation", "animationDuration:duration", "swiping"],
    contentId: "",
    active: 0,
    animation: "",
    animationDuration: 200,
    swiping: false,
    ukSwitcher: "",
    connect: (0, _object.computed)("contentId", function () {
      const id = this.contentId;
      return (0, _utils.isEmpty)(id) ? "" : `#${id}`;
    })
  });

  _exports.default = _default;
});