define("ember-uikit/components/uk-switcher/nav", ["exports", "@ember/component", "@ember/object", "ember-uikit/templates/components/uk-switcher/nav"], function (_exports, _component, _object, _nav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPES = void 0;
  const TYPES = {
    TAB: {
      name: "tab",
      componentName: "uk-tab"
    },
    SUBNAV: {
      name: "subnav",
      componentName: "uk-subnav"
    }
  };
  _exports.TYPES = TYPES;

  var _default = _component.default.extend({
    layout: _nav.default,
    type: TYPES.TAB,
    tagName: "",
    componentName: (0, _object.computed)("type", function () {
      return TYPES[Object.keys(TYPES).find(k => TYPES[k].name === this.type)].componentName;
    })
  });

  _exports.default = _default;
});