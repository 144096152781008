define("ember-uikit/templates/components/uk-switcher/nav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4gUtYWkh",
    "block": "[[[18,5,[[28,[37,1],null,[[\"component\"],[[50,[33,3],0,null,[[\"contentId\",\"active\",\"animation\",\"animationDuration\",\"swiping\"],[[33,4],[30,1],[30,2],[30,3],[30,4]]]]]]]]],[1,\"\\n\"]],[\"@active\",\"@animation\",\"@animationDuration\",\"@swiping\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"componentName\",\"contentId\"]]",
    "moduleName": "ember-uikit/templates/components/uk-switcher/nav.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});