define("ember-uikit/templates/components/uk-switcher", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "CqybBn7J",
    "block": "[[[18,1,[[28,[37,1],null,[[\"nav\",\"content\"],[[50,\"uk-switcher/nav\",0,null,[[\"contentId\",\"active\",\"animation\",\"animationDuration\",\"swiping\"],[[33,3],[33,4],[33,5],[33,6],[33,7]]]],[50,\"uk-switcher/content\",0,null,[[\"elementId\"],[[33,3]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"contentId\",\"active\",\"animation\",\"animationDuration\",\"swiping\"]]",
    "moduleName": "ember-uikit/templates/components/uk-switcher.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});