define("ember-uikit/components/uk-width", ["exports", "@ember/component", "ember-uikit/mixins/width", "ember-uikit/templates/components/uk-width"], function (_exports, _component, _width, _ukWidth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const UkWidthComponent = _component.default.extend(_width.default, {
    layout: _ukWidth.default
  });

  UkWidthComponent.reopenClass({
    positionalParams: ["width"]
  });
  var _default = UkWidthComponent;
  _exports.default = _default;
});