define("ember-uikit/components/uk-tab/item", ["exports", "@ember/component", "ember-uikit/mixins/evented", "ember-uikit/templates/components/uk-tab/item"], function (_exports, _component, _evented, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_evented.default, {
    layout: _item.default,
    tagName: "li",
    classNameBindings: ["active:uk-active", "disabled:uk-disabled"],
    active: false,
    disabled: false
  });

  _exports.default = _default;
});