define("ember-uikit/components/uk-card", ["exports", "@ember/component", "ember-uikit/mixins/color", "ember-uikit/mixins/size", "ember-uikit/mixins/width", "ember-uikit/templates/components/uk-card"], function (_exports, _component, _color, _size, _width, _ukCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CARD_COLOR_OPTIONS = void 0;
  const CARD_COLOR_OPTIONS = [_color.COLOR_OPTIONS.DEFAULT, _color.COLOR_OPTIONS.PRIMARY, _color.COLOR_OPTIONS.SECONDARY];
  _exports.CARD_COLOR_OPTIONS = CARD_COLOR_OPTIONS;

  var _default = _component.default.extend(_color.default, _size.default, _width.default, {
    COLOR_OPTIONS: CARD_COLOR_OPTIONS,
    _color: _color.COLOR_OPTIONS.DEFAULT,
    layout: _ukCard.default,
    colorTemplate: "uk-card-$color$",
    sizeTemplate: "uk-card-$size$",
    classNames: ["uk-card"],
    classNameBindings: ["hover:uk-card-hover"],
    hover: false
  });

  _exports.default = _default;
});