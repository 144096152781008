define("ember-uikit/templates/components/uk-subnav/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/swqFJTh",
    "block": "[[[10,3],[14,6,\"#\"],[12],[18,1,null],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-uikit/templates/components/uk-subnav/item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});