define("ember-uikit/components/uk-switcher/content/item", ["exports", "@ember/component", "uikit", "ember-uikit/templates/components/uk-switcher/content/item"], function (_exports, _component, _uikit, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // empty function as default event handler
  const noop = () => {};

  var _default = _component.default.extend({
    layout: _item.default,
    tagName: "li",

    setEvents() {
      const events = {
        beforeshow: this["on-beforeshow"] ?? noop,
        show: this["on-show"] ?? noop,
        shown: this["on-shown"] ?? noop,
        beforehide: this["on-beforehide"] ?? noop,
        hide: this["on-hide"] ?? noop,
        hidden: this["on-hidden"] ?? noop
      };

      for (const event in events) {
        _uikit.default.util.on(this.element, event, events[event]);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.setEvents();
    }

  });

  _exports.default = _default;
});