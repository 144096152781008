define("ember-uikit/-private/media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MEDIA_OPTIONS = {
    SMALL: "@s",
    MEDIUM: "@m",
    LARGE: "@l",
    XLARGE: "@xl"
  };
  var _default = MEDIA_OPTIONS;
  _exports.default = _default;
});