define("ember-uikit/components/uk-switcher/content", ["exports", "@ember/component", "ember-uikit/templates/components/uk-switcher/content"], function (_exports, _component, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _content.default,
    tagName: "ul",
    classNames: ["uk-switcher"]
  });

  _exports.default = _default;
});