define("ember-toggle/components/x-toggle-switch/component", ["exports", "@ember-decorators/component", "ember-toggle/components/x-toggle-switch/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let XToggleSwitch = (_dec = (0, _component.layout)(_template.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.computed('forId', 'labelDisabled'), _dec4 = Ember.computed('theme'), _dec5 = Ember._action, classic(_class = _dec(_class = _dec2(_class = (_class2 = class XToggleSwitch extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "labelDisabled", false);
    }

    get effectiveForId() {
      return this.labelDisabled ? null : this.forId;
    }

    get themeClass() {
      let theme = this.theme || 'default';
      return `x-toggle-${theme}`;
    }

    handlePan(touchData) {
      if (this.disabled) {
        return;
      }

      const isToggled = touchData.current.distanceX > 0;
      this.sendToggle(isToggled);

      this._disableLabelUntilMouseUp();
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);

      this._removeListener();
    }
    /*
      When you pan with a mouse and release the mouse button over the <label>
      element, a click event happens and returns the toggle to its initial
      state. :(
       To prevent this, we need to make the label non-functional until after the
      mouse button is released.
     */


    _disableLabelUntilMouseUp() {
      if (this.labelDisabled) {
        return;
      }

      const _listener = () => {
        Ember.run.next(() => {
          if (this.isDestroying || this.isDestroyed) {
            return;
          }

          this._removeListener();

          this.set('labelDisabled', false);
        });
      };

      this.setProperties({
        labelDisabled: true,
        _listener
      });
      document.addEventListener('mouseup', _listener);
    }

    _removeListener() {
      const _listener = this._listener;

      if (_listener) {
        document.removeEventListener('mouseup', _listener);
        this.set('_listener', null);
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "effectiveForId", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "effectiveForId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "themeClass", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "themeClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handlePan", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handlePan"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = XToggleSwitch;
});