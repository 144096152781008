define("ember-uikit/components/uk-list", ["exports", "@ember/component", "ember-uikit/mixins/width", "ember-uikit/templates/components/uk-list"], function (_exports, _component, _width, _ukList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_width.default, {
    layout: _ukList.default,
    tagName: "ul",
    classNames: ["uk-list"],
    classNameBindings: ["divider:uk-list-divider", "striped:uk-list-striped", "bullet:uk-list-bullet", "large:uk-list-large"],
    divider: false,
    striped: false,
    bullet: false,
    large: false
  });

  _exports.default = _default;
});