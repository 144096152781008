define("ember-uikit/templates/components/uk-subnav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "e6VsZ7k0",
    "block": "[[[18,1,[[28,[37,1],null,[[\"item\",\"link-item\"],[[50,\"uk-subnav/item\",0,null,null],[50,\"uk-subnav/link-item\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-uikit/templates/components/uk-subnav.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});