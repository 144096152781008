define("ember-uikit/components/uk-icon", ["exports", "@ember/component", "ember-uikit/templates/components/uk-icon"], function (_exports, _component, _ukIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const UkIconComponent = _component.default.extend({
    layout: _ukIcon.default,
    tagName: "span",
    attributeBindings: ["ukIcon:uk-icon", "icon", "ratio"],
    icon: "",
    ratio: 1,
    ukIcon: ""
  });

  UkIconComponent.reopenClass({
    positionalParams: ["icon"]
  });
  var _default = UkIconComponent;
  _exports.default = _default;
});