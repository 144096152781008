define("ember-uikit/components/uk-sortable", ["exports", "@ember/component", "uikit"], function (_exports, _component, _uikit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // empty function as default event handler
  const noop = () => {};

  var _default = _component.default.extend({
    classNames: ["uk-sortable"],
    attributeBindings: ["group", "animationDuration:animation", "threshold", "clsItem:cls-item", "clsPlaceholder:cls-placeholder", "clsDrag:cls-drag", "clsDragState:cls-drag-state", "clsBase:cls-base", "clsNoDrag:cls-no-drag", "clsEmpty:cls-empty", "clsCustom:cls-custom", "handle", "ukSortable:uk-sortable"],
    // Component Options
    group: null,
    animationDuration: null,
    threshold: null,
    clsItem: null,
    clsPlaceholder: null,
    clsDrag: null,
    clsDragState: null,
    clsBase: null,
    clsNoDrag: null,
    clsEmpty: null,
    clsCustom: null,
    handle: null,
    ukSortable: "",

    setEvents() {
      const events = {
        start: this["on-start"] ?? noop,
        stop: this["on-stop"] ?? noop,
        moved: this["on-moved"] ?? noop,
        added: this["on-added"] ?? noop,
        removed: this["on-removed"] ?? noop
      };

      for (const event in events) {
        _uikit.default.util.on(this.element, event, events[event]);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.setEvents();
    }

  });

  _exports.default = _default;
});