define("ember-uikit/components/uk-modal", ["exports", "@ember/application", "@ember/component", "@ember/debug", "@ember/object/internals", "@ember/runloop", "@glimmer/tracking", "uikit", "ember-uikit/templates/components/uk-modal"], function (_exports, _application, _component, _debug, _internals, _runloop, _tracking, _uikit, _ukModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const noop = () => {};

  let UkModal = (_class = class UkModal extends _component.default {
    get modalHeaderId() {
      return `${this.modalId}-header`;
    }

    get modalSelector() {
      return `#${this.modalId}`;
    }

    get containerSelector() {
      // Only set the container to the default if no query string was passed as argument.
      if (typeof this.container !== "string") {
        return (0, _application.getOwner)(this).rootElement || "body";
      }

      return this.container;
    }

    get containerElement() {
      const document = (0, _application.getOwner)(this).lookup("service:-document");
      const containerElement = document.querySelector(this.containerSelector);
      (true && !(containerElement) && (0, _debug.assert)(`Your specified @container query selector ${this.container} did not return an element. Check your query selector and check if the element actually exists.`, containerElement));
      return containerElement;
    }

    constructor() {
      super(...arguments);

      _defineProperty(this, "layout", _ukModal.default);

      _defineProperty(this, "modalId", `modal-${(0, _internals.guidFor)(this)}`);

      _initializerDefineProperty(this, "modal", _descriptor, this);

      _initializerDefineProperty(this, "container", _descriptor2, this);

      _initializerDefineProperty(this, "modalClass", _descriptor3, this);

      _initializerDefineProperty(this, "dialogClass", _descriptor4, this);

      _initializerDefineProperty(this, "btnClose", _descriptor5, this);

      _initializerDefineProperty(this, "escClose", _descriptor6, this);

      _initializerDefineProperty(this, "bgClose", _descriptor7, this);

      _initializerDefineProperty(this, "stack", _descriptor8, this);

      _initializerDefineProperty(this, "clsPage", _descriptor9, this);

      _initializerDefineProperty(this, "clsPanel", _descriptor10, this);

      _initializerDefineProperty(this, "selClose", _descriptor11, this);

      _initializerDefineProperty(this, "isAnimating", _descriptor12, this);

      _initializerDefineProperty(this, "focusTrapActive", _descriptor13, this);

      _defineProperty(this, "modalObserver", null);

      this.eventHandlers = {
        hide: async event => {
          if (event.currentTarget === event.target) {
            if (this.visible) {
              await (this["on-hide"] ?? noop)();
            }
          }
        },
        hidden: event => {
          if (event.currentTarget === event.target) {
            this.isAnimating = false;
          }
        },
        show: async event => {
          if (event.currentTarget === event.target) {
            if (this.visible) {
              await (this["on-show"] ?? noop)();
            }
          }
        },
        shown: event => {
          if (event.currentTarget === event.target) {
            this.isAnimating = false;
          }
        },
        beforehide: event => {
          if (event.currentTarget === event.target) {
            this.isAnimating = true;
          }
        },
        beforeshow: event => {
          if (event.currentTarget === event.target) {
            this.isAnimating = true;
          }
        }
      };
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      this.modal = _uikit.default.modal(`#${this.modalId}`, {
        escClose: this.escClose,
        bgClose: this.bgClose,
        stack: this.stack,
        container: this.containerSelector,
        clsPage: this.clsPage,
        clsPanel: this.clsPanel,
        selClose: this.selClose
      });
      (0, _runloop.scheduleOnce)("afterRender", this, "_setupEvents");
    }

    didReceiveAttrs() {
      super.didReceiveAttrs();
      (0, _runloop.scheduleOnce)("afterRender", this, "toggleModal");
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);

      if (this.modal) {
        this._teardownEvents();

        this.modal.$destroy(true);
        this.modal = null;
      }
    }

    _setupEvents() {
      Object.keys(this.eventHandlers).forEach(event => {
        _uikit.default.util.on(this.modalSelector, event, this.eventHandlers[event]);
      }); // Setup a observer so we can avtivate the focus trap only once
      // the modal has stopped animating (otherwise this will cause errors).
      // To check if the modal has stopped animating, we can just check for
      // the `uk-open` class on the modal. If it extists then its finished.

      this.modalObserver = new MutationObserver(mutationList => {
        const mutations = mutationList.filter(_ref => {
          let {
            target,
            attributeName
          } = _ref;
          return target.id === this.modalId && attributeName === "class";
        }).map(mutation => mutation.target.classList); // Short-circuit if no mutations match the filter

        if (!mutations.length) {
          return;
        }

        this.focusTrapActive = mutations.every(classList => classList.contains("uk-open"));
      });
      this.modalObserver.observe((0, _application.getOwner)(this).lookup("service:-document").querySelector(this.modalSelector), {
        attributes: true,
        subtree: true,
        childList: false
      });
    }

    _teardownEvents() {
      Object.keys(this.eventHandlers).forEach(event => {
        _uikit.default.util.off(this.modalSelector, event, this.eventHandlers[event]);
      });
      this.modalObserver.disconnect();
    }

    async toggleModal() {
      if (!this.modal) return;

      if (this.visible) {
        await this.modal.show();
      } else {
        await this.modal.hide();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "container", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modalClass", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dialogClass", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "btnClose", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "escClose", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "bgClose", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "stack", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "clsPage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "uk-modal-page";
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "clsPanel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "uk-modal-dialog";
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selClose", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [".uk-modal-close", ".uk-modal-close-default", ".uk-modal-close-outside", ".uk-modal-close-full"].join(", ");
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isAnimating", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "focusTrapActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.default = UkModal;
});